.playerCTA {
    display: flex;
    flex-flow: column;
    text-align: left;
}
.playerCTA label {
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
}
.CTAlabel{
    text-align: left;
    display: block;
    padding-bottom: 6px;    
    font-weight: 600;
    font-size: 15px;    
}
