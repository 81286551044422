.vd_config{
  text-align: left; 
  margin: 20px auto; 
  padding: 20px 50px;
}
.vd_config  h2 {
    margin: 10px 0 50px;
    font-size: 22px;
    color: #09527a;
    text-transform: capitalize;
}
.vd_config .hotspot_position_1 h4 {
    font-size: 17px;
    font-weight: 400;
}
.vd_config .hotspot_position_1 {
    display: flex;
    flex-flow: column;
}
.vd_config .hotspot_position_1 select {
    margin: 0 0 25px 0;
    width: 450px;
    border-radius: 0px !important;
    box-shadow: 0 1px 2px 0 rgb(29 37 45 / 10%) !important;
    border: solid 1px rgba(127, 125, 142, 0.1) !important;
    /* background: #f0f9ff73 !important; */
    font-size: 15px !important;
    height: 50px;
}
.config_poster {
    display: flex;
    flex-flow: column;
    margin-top: 27px;
}
.config_poster_inp {
    width: 450px;
    border-radius: 0px !important;
    box-shadow: 0 1px 2px 0 rgb(29 37 45 / 10%) !important;
    border: solid 1px rgba(127, 125, 142, 0.1) !important;
    /* background: #f0f9ff73 !important; */
    font-size: 15px !important;
    height: 50px;
    padding: 0px 17px;
    margin-bottom:25px;
}
.config_poster p {
    margin: 8px 0;
    text-align: center;
    font-size: 18px;
}
#myfile {
padding-top: 10px;
}
.video_branching {
    display: flex;
    flex-flow: column;
    border: 1px dotted #043348;
    padding: 10px 10px;
    margin-bottom: 14px;
}
.vd_config .hotspot_position_1 select:focus-visible {
    outline: 0;
}
.label_config{
    margin: 5px 0 10px;
    font-size: 16px;
    color: #043348;
    font-weight: 500;    
}

.btn_save {
    background: #043348;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    color: #fff;
    width: 120px;
    height: 42px;
    font-size: 20px;
    border: 0;
    margin-top: 18px;
    margin-bottom: 10px;
    cursor: pointer;
}

.btn_cancel {
    background: #1287bd;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    color: #fff;
    width: 120px;
    height: 42px;
    font-size: 20px;
    border: 0;
    margin-top: 30px;
    margin-bottom: 50px;
    cursor: pointer;
    margin-left: 10px;
}

.basic_branching_setting{
    margin-bottom: 30px;
}