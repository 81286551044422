.answer_imgs{
width: 20px;
margin-left: 30px;
margin-bottom: 20px;
cursor: pointer;
}
.showtime_quiz{
    width: 60px;
    height: 40px;
    border: 1px solid #00000052;
    border-radius: 5px; 
    text-align: center;
    /* margin: 0 4px; */
    font-size: 16px;
    padding-left: 10px;
}
.vd_config select{
    margin: 0 0 25px 0;
    width: 450px;
    border-radius: 0px !important;
    box-shadow: 0 1px 2px 0 rgb(29 37 45 / 10%) !important;
    border: solid 1px rgba(127, 125, 142, 0.1) !important;
    font-size: 15px !important;
    height: 50px;
    padding: 0 11px;
}
.vd_config select:focus-visible{
    outline: 0;
}
.vd_config h4 {
    font-size: 17px;
    font-weight: 400;
}
/* textarea#poster_img {
    height: 100px;
    margin-bottom: 25px !important;
    margin-left: 0 !important;
} */
div#root {
    margin-bottom: 50px;
}
textarea {
    height: 90px !important;
}
.formQzCom{
    overflow-y: auto;
}
/* .formQzCom h2 {
    font-size: 20px;
    margin-bottom:20px;
} */
.formQzCom h2{
    padding-bottom: 5px;
    border-bottom: 1px solid #0000001a;   
    color: #043348;
    font-weight: bold;
}
.formQzCom h5 {
    font-size: 18px;
}
.formQuizFields legend {
    background: linear-gradient(3deg,#11919c,#000851);
    color: white;
    padding: 5px 10px;
    color: white;
    padding: 5px 10px;
    font-size: calc(1.275rem + .3vw);
    margin-bottom: 0;
    width: auto;
    float: none;
}
.formQuizFields fieldset {
    background-color: #eeeeee;
    border: 1px dashed rgba(0, 0, 0, 0.52);
    padding: 10px;
    margin-bottom: 40px !important;
}
.formQuizFields input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
}
.formQuizFields input[type="radio"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
}
.quizSelectStyle{
margin-left: 29px !important;   
}