/* /footer css start / */
.dash_footer {
    background: linear-gradient(3deg, #11919c 0%, #000851 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px 0 15px 8px;
    z-index:99999;
}

.dash_copyright p {
    font-size: 15px;
    color: #fff;
    margin: 0;
}

.dash_foot_menu ul {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin-bottom: 0;
}

.dash_foot_menu ul li a {
    padding: 0 10px;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
}

.dash_foot_menu {
    padding-right: 25px;
}

/* / footer css end / */