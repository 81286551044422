.template_main_div {
    padding: 15px;
}

.btn_div{
    padding: 10px;
}

.content_div{
    padding: 10px;
}

.modal {
    --bs-modal-width: 85% !important;
}
.flex_img h2{
font-size: 17px !important;   
margin: 0 !important;
}
.flex_img {
    align-items: center;
}
.dash_btns_add input {
    width: 18px;
    margin: 0 5px 0 2px;
}
.video_grid{
    box-shadow: 0px 4px 7px 0px #0000001a;    
    margin-bottom: 15px;
}
