.loader_container{
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    /* margin-top: -100px; */
    z-index: 9999;
    top:0;
    left:0;
}

/* .inner_container{ */
    /* background-color: #032535; */
    /* padding: 20px; */
    /* border-radius: 8px; */
/* } */