/* video-js css start*/

h1{
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
}
h2{
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
}
h3{
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important; 
}
h4{
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
}
h5{
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
}
h6{
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
}
:root {
    --playerWidth: 640px;
    --playerHeight: 360px;
}

body {
    font-family: sans-serif !important;
    /* font-size: 14px !important;
    line-height: 20px; */
}

.video-js .vjs-big-play-button {
    top: calc(50% - 50px) !important;
    left: calc(50% - 50px) !important;
}

/* video-js css end*/
.draggable_parent_div {
    background: none;
    /* width: 640px;
    height: 360px; */
    position: relative;
    /* overflow: 'auto'  */
}

.draggable_child_container {
    cursor: pointer;
    resize: none;
    overflow: none;
    border: none;
    position: absolute;
    top: 0px;
    bottom: auto;
    padding: 1px;
    /* width: 150px; */
    max-width: 500px;
    max-height: 500px;
    /* cursor: all-scroll; */
    display: block;
    border: 1px outset skyblue;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.draggable_child_div {
    cursor: pointer;
    resize: none;
    overflow: none;
    border: none;
    position: absolute;
    top: 0px;
    bottom: auto;
    /* padding: 1px; */
    box-sizing: content-box !important;
    /* width: 150px; */
    /* max-width: 450px; */
    /* max-height: 300px; */
    /* cursor: all-scroll; */
    display: none;
    z-index: 99;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.player_dyn_img {
    width: 100%;
    height: auto;
    pointer-events: none;
}

.player_dyn_img_label {
    text-align: center;
    color: #fff;
    background: black;
    font-size: 16px;
    opacity: 0.7;
}

.player_popup {
    background: #fff;
    position: absolute;
    top: 30px;
    left: 50px;
    max-height: 300px;
    width: 540px;
    border: 1px solid #00000052;
    color: #000;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    /* align-items: center; */
    border-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9999;
}

.player_popup_child {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: max-content;
    padding: 10px !important;
    text-align: center;

}
.player_popup_child p{
    margin: 5px 0px;
    text-align: center;
}
.player_popup_child span{
    margin: 5px 0px;
    text-align: center;
}
.player_popup_child div{
    margin: 5px 0px;
    text-align: center;
}
.modal_cross {
    position: absolute;
    background-color: transparent;
    background: url('../../../public/assets/quizcross.png');
    top: 0;
    right: 0;
    border: 0;
    width: 20px;
    height: 20px;
    bottom: 40px;
    float: left;
    background-size: cover;
    cursor: pointer;
}

.player_popup_branching {
    /* background: rgba(0, 0, 0, 0.9); */
    position: absolute;
    top: 0;
    /* bottom: 40px; */
    left: 0;
    /* right: 0; */
    /* width: max-content; */
    z-index: 9;
    color: #fff;
    /* justify-content: center; */
    /* border: 1px outset skyblue; */
    display: flex;
    /* margin: 0 auto; */
}

.branching_edit_btns {
    position: absolute;
    top: 50%;
    right: 0;
}

.template_div {
    position: absolute;
    /* bottom: 0; */
    top: 0;
    left: 0;
    /* right: 0; */
    width: 100%;
    height: auto;
    /* opacity: 0.5; */
    z-index: 99;
    /* height: 360px; */
}
.branching_edit_btns button {
    border: 0;
    font-size: 14px;
    color: #fff;
    padding: 5px 5px;
}
.edit_btn_danger {
    position: absolute;
    top: 50%;
    right: 0;
    border: 0;
    color: #fff;
    /* padding: 2px 12px; */
    z-index: 100;
    font-size: 13px;
    border-radius: 50px;
    padding: 5px 16px !important;
    background: #589bff;
}
.edit_btn_danger img {
    width: 15px;
}
/* #btn_return_fixed {
    font-size: 13px;
    border-radius: 50px;
    padding: 5px 16px !important;
    background: #589bff;
} */

/* Hotsport icons css Start*/
.shopping_02_cart_icon {
    position: relative;
    width: auto;
    /* max-width: 45px; */
    height: auto;
    /* cursor: pointer; */
    border-radius: 50%;
    background: #41abe5;
    /* box-shadow: 0 0 60px 10px white; */
    padding: 9px;
}

.shopping_02_cart_icon:before {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    box-shadow: 0 0 10px 2px white;
}

.shopping_02_cart_icon:after {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    box-shadow: 0 0 10px 2px white;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    100% {
        transform: scale(2.3);
        opacity: 0;
    }
}

.shopping_02_cart_icon img {
    width: 28px !important;
    height: 28px;
    margin: 0 auto;
}

/* Hotsport icons css End*/

/* Text container css */
.text-container {
    position: relative;
    /* width: auto; */
    /* max-width: 45px; */
    /* height: auto; */
    cursor: pointer;
    /* keep this property on item object as not every image will be clickable */
    /* border-radius: 10%; */
    background: #ffffffed;
    /* box-shadow: 0 0 60px 10px white; */
    padding: 9px;
    /* width: 100%; */
    /* height: auto; */
    overflow-y: scroll;
    opacity: 1;
}

/* .text-container:before {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    border-radius: 22%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    box-shadow: 0 0 10px 2px white;
}

.text-container:after {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    border-radius: 22%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    box-shadow: 0 0 10px 2px white;
} */

.video-js .my-image {
    width: 25px;
    /* background: url(https://placekitten.com/38/28) center center no-repeat; */
    position: absolute;
    height: 25px;
    margin-top: -12px;
    z-index: 1;
}

.video-js .my-hotspot {
    width: 25px;
    /* background: url(https://placekitten.com/38/28) center center no-repeat; */
    position: absolute;
    height: 25px;
    margin-top: -12px;
    z-index: 1;
}

.video-js .my-text {
    width: 25px;
    /* background: url(https://placekitten.com/38/28) center center no-repeat; */
    position: absolute;
    height: 25px;
    margin-top: -12px;
    z-index: 1;
}

.first_video_btns {
    display: flex;
    flex-flow: column;
    padding: 5px;
    border: 0;
    opacity: .7;
    transition: 0.7s;
    justify-content: space-between;
    align-items: center;
    margin: 0 8px;
    background: #ffffffbf;
    cursor: pointer;
    /* width: 110px; */
    width: calc(var(--playerWidth)/6);
}

.first_video_btns:hover {
    background: #fffffff5;
}

.first_video_btns img {
    /* width: 55px; */
    width: calc(var(--playerWidth)/7);
    height: calc(var(--playerHeight)/5);
}

.first_video_btns span {
    font-size: 13px;
    line-height: 20px;
    color: #000;
    /* font-weight: 600; */
    text-align: center;
    padding: 5px 0 0 0;
}

.branching_icons {
    display: flex;
    flex-flow: row;
    /* justify-content: center; */
}

.quizRenderBg {
    background: #fff;
    padding: 10px 30px;
    /* width: 480px !important; */
}

.formQzCom {
    width: 480px !important;
}
.formQzCom h5 {
    margin: 5px 0 5px 0;
}
/* .fieldsLabel, .fieldOptions{
    background: #fff;
} */
.formQzBg {
    background: #fff;
    /* height: 420px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
}

.formQzBg .row {
    margin: 0 0 5px 0;
}

.fieldsLabel {
    font-size: 15px;
    margin: 6px 0 10px 0;
    font-weight: 600;
    color: #222;
    display: block;
}

.formQzCom input {
    margin-bottom: 15px;
}

.formQzCom select {
    margin-bottom: 15px;
}

.formQzCom input[type="radio"] {
    margin-left: 10px;
    margin-bottom: 0 !important;
    margin-right: 10px;
}

.formQzCom input[type="checkbox"] {
    margin-right: 8px;
}

.formQzCom select:focus {
    outline: none;
}

.formQzCom span {
    font-size: 15px;
}

.formQzCom input[type="checkbox"] {
    margin-left: 5px;
    margin-bottom: 0 !important;
}

.formQzCom textarea {
    margin-bottom: 15px !important;
    height: 80px;
}

.quizBg button {
    margin-bottom: 20px;
}

.formQzCom button {
    width: auto;
    max-width: 150px;
    /* height: 38px; */
    font-size: 16px;
    margin: 0 0 15px 0;
}

.formQzBg::-webkit-scrollbar {
    width: 8px;
}

.formQzBg.formQzCom {
    max-height: 285px;
    height: auto;
}

.formQzBg::-webkit-scrollbar-thumb {
    background: #888;
}

.formQzBg::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.optionsRow {
    display: flex;
    flex-flow: row wrap;
}

.quizCross {
    position: absolute;
    top: -3px;
    right: -30px;
    width: 20px;
    height: 20px;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background: url('../../../public/assets/quizcross.png');
}

.playerBranchingBtns button {
    font-size: 14px;
    margin: 0 2px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #000;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(3deg, #11919c, #000851);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(3deg, #000851, #11919c);
}

textarea {
    height: 90px !important;
}

.draggable_child_div_cta {
    cursor: pointer;
    resize: none;
    overflow: none;
    border: none;
    position: absolute;
    top: 0px;
    bottom: auto;
    padding: 1px;
    /* width: 150px; */
    max-width: 450px;
    max-height: 300px;
    /* cursor: all-scroll; */
    display: block;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* overflow-y: auto;
    overflow-x: hidden; */

}

.video-js .my-form {
    width: 25px;
    /* background: url(https://placekitten.com/38/28) center center no-repeat; */
    position: absolute;
    height: 25px;
    margin-top: -12px;
    z-index: 1;
}

.form_opt_images {
    text-align: center;
}

.textPara p {
    margin: 10px 0;
}

.text-button .text-container-r {
    background: #fff;
    color: #333;
    padding: 5px 8px !important;
    border: 1px solid #000;
    border-radius: 5px;
    /* font-size: 14px; */
    /* font-weight: 500; */
    box-shadow: 2px 3px 5px #0d4887;
}

.text-button .text-container-r p {
    margin: 0 !important;
}

.cta_button {
    background: #fff;
    color: #333;
    padding: 5px 8px;
    /* border: 5px solid #000; */
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 2px 3px 5px #0d4887;
}

.cta_button p {
    margin: 0;
}

.movingOptionBtn {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: flex !important;
}

.movingOptionBtn button {
    margin: 5px 10px;
    border-radius: 50px !important;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 93%);
}

.form_opt_edit {
    display: flex;
    align-items: center;
}

.formZindex {
    z-index: 999;
}

.text-button {
    z-index: 9;
}

.hotspotZindex {
    z-index: 12;
}

.parentOverlay:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 8;
}

.draggable_child_div_new {
    height: auto;
    width: auto;
    cursor: all-scroll;
    resize: both;
    overflow: auto;
    border: 1px outset skyblue;
    position: absolute;
    top: 0px;
    bottom: auto;
    padding: 1px;
    box-sizing: content-box !important;
    /* width: 150px; */
    max-width: 450px;
    max-height: 300px;
    /* cursor: all-scroll; */
    display: block;
    z-index: 99;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.customizedCTA_1 {
    display: flex;
    align-items: center;
    flex-flow: row;
    background: #4b4943de;
    width: max-content;
    padding: 6px 7px;
    border-radius: 50px;
}

.customizedCTAImg_1 {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    pointer-events: none;
}

.customizedCTATxt_1 {
    font-size: 15px;
    font-weight: bold;
    padding: 0 10px;
    color: #fff;
    max-width: 160px;
    width: auto;
}

.customizedCTABtn_1 {
    background: #003279;
    color: #fff;
    border: 0;
    border-radius: 50px;
    width: auto;
    height: auto;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
}

.customizedCTA_2 {
    display: flex;
    align-items: center;
    flex-flow: row;
    background: #fffc;
    width: max-content;
    padding: 6px 7px;
    border-radius: 10px;
}

.customizedCTAImg_2 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    pointer-events: none;
}

.customizedCTATxt_2 {
    font-size: 15px;
    font-weight: bold;
    padding: 0 10px;
    color: #333;
    max-width: 160px;
    width: auto;
}

.customizedCTABtn_2 {
    background: #0d6efd;
    color: #fff;
    border: 0;
    border-radius: 50px;
    width: auto;
    height: auto;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
}

/* .first_video_btns:nth-child(odd) {
   
}
.first_video_btns:nth-child(even) {
    background: #66a8ccb3;
} */

.vjs-fullscreen-control {
    display: none !important;
}

.vjs-picture-in-picture-control {
    display: none !important;
}

.branchingVertical {
    flex-flow: column;
}

.branchingVertical .first_video_btns {
    margin: 3px 0;
}

.img_branching {
    background: #ffffffbf;
    padding: 5px;
    opacity: .7;
}

.player_dyn_img_label_branching {
    text-align: center;
    color: black;
    background: #ffffffbf;
    font-size: 16px;
    opacity: .7;
}

.playerTitleBtns {
    text-align: center;
    margin: 12px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.playerVideoTitle h2 {
     font-size: 15px;
    font-weight: 600;
    border: 1px solid #00000057;
    width: max-content;
    padding: 5px 30px;
    margin: 0 0 0 30px;
    border-radius: 5px;
    color: #043348;
}
.playerBranchingBtns {
    margin-right: 25px;
}
.hideAutoFixedTemp{
    visibility: hidden;
}