.multiselect_interaction{
    height: 80px !important;
}

.video_interaction {
    display: flex;
    flex-flow: column;
    border: 1px dotted #043348;
    padding: 10px 10px;
    margin-bottom: 14px;
}
.BtnMargin {
    margin-top: 20px !important;
}