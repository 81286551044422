.loginform_1 {
    width: 100%;
    height: 100vh;
    background: linear-gradient(11deg, rgba(2,0,36,1) 0%, rgb(14 14 155) 5%, rgba(0,212,255,1) 100%);
    background: url('../../../../public/assets/login-background.png');
    padding-top: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.loginform_1 img {
    margin: 0 auto;
    justify-content: center;
    display: flex;
    padding-top: 20px;
}

.login_form h2 {
    text-align: center;
    color: #fff;
    font-weight:bold;
    font-size: 25px;
    line-height: 32px;
    margin: 0 0 12px 0;
}

.login_form input {
    display: block;
    padding: 0px 10px;
    border-radius: 3px;
    background: #F2F2F2;
    height: 3em;
    line-height: 2.5em;
    width: 300px;
    margin: 5px auto 15px;
    border: 0;
}

.login_form {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 370px;
    /* margin: 0 auto; */
    background: #0b5d804f;
    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2),0 12px 17px 2px rgba(0,0,0,.14),0 5px 22px 4px rgba(0,0,0,.12)!important;
    border-radius: 10px;
    padding: 20px 5px;
    margin-right: 24px;
}

.login_form button {
    background: #043348;
    width: 150px;
    height: 40px;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    border: 0;
    border-radius: 5px;
    margin: 12px 0 20px;
    cursor: pointer;
}

.forget_form span {
    font-size: 16px;
    line-height: 30px;
    display: inline-block;
    padding: 0 12px;
}
.login_reset {
    font-size: 15px !important;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
}
input:focus-visible {
    outline: 0;
}