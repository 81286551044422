.edit_time .editshowtime input {
    width: 60px;
    height: 40px;
    border: 1px solid #00000052;
    border-radius: 5px;
    text-align: center;
    margin: 0 4px;
    font-size: 16px;
    padding-left: 10px;
}

.editshowtime {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.edithidetime {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.edithidetime input {
    width: 60px;
    text-align: center;
    margin: 0 4px;
    font-size: 16px;
    padding-left: 10px;
    height: 40px;
    border: 1px solid #00000052;
    border-radius: 5px;
}

.edit_time {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .edit_time .editshowtime input {
    width: 130px;
    height: 40px;
    border: 1px solid #00000052;
    border-radius: 5px;
} */

.editshowtime {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.edithidetime {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
/* 
.edithidetime input {
    width: 130px;
    height: 40px;
    border: 1px solid #00000052;
    border-radius: 5px;
} */

.edit_time {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit_image_box h2 {
    font-size: 23px;
    line-height: 45px;
    /* color: #0a5f81; */
    font-weight: 500;
    margin: 15px 0 15px 0;
}

.edit_image_div img {
    /* width: 200px; */
    border-radius: 5px;
}

.edit_image_box button {
    background: #11919c;
    color: #ffffff;
    padding: 7px 16px;
    border: 0;
    border-radius: 5px;
    margin: 15px;
}

.edit_image_box button a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}

.edit_selectbox select {
    /* width: 220px;
    height: 40px; */
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    cursor: pointer;
}
.show_message{
    margin: 0 auto;
    display: flex;
    justify-content: center;    
}
.edit_checkbox {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    margin: 15px 10px;
}
.edit_checkbox span {
    margin-left: 8px;
}
.action label{
    margin: 13px 0;
    font-size: 18px;    
}
.edit_checkbox input {
    width: 18px;
    height: 18px;
}
.edit_checkbox label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-left: 8px;
}
.edit_time label {
    font-size: 16px;
    margin-bottom: 2px;
}
.edit_checkbox input{
    width: 18px;
    height: 18px;   
    margin-bottom: 0 !important; 
}
#edit_modal {
    width: auto;
    height: auto;
    border: 1px solid #0000000d;
    margin: 15px 40px;
    border-radius: 5px;
}
.edit_modal_inner {
    background-color: #fff;
    margin: 0 auto;
    padding: 20px 30px 20px 30px;
    position: relative;
    text-align: center;
}

.cross_img {
    width: 27px;
    right: 17px;
    display: block;
    position: absolute;
    top: 10px;
    cursor: pointer;
}

.edit_image_box {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
}

.edit_selectbox_div {
    margin: 5px auto 10px;
    display: flex;
    flex-flow:column;
    justify-content: center;
}
.edit_modal_fl input{
    display: flex;
    justify-content: center;    
    margin: 0 auto !important;
    border-radius: 5px;
}
.edit_modal_btn {
    background: #3d9937;
    color: #fff;
    padding: 6px 20px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 18px;
    border: none;
}
.config_poster_inp:focus {
    outline: none;
}
.edit_form_cancel {
    color: #b60100;
    border: none;
    background: transparent;
    font-size: 20px;
}

.edit_modal_save {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
.img_input_txt input {
    border: 1px solid #0000003d;
    padding: 8px 12px;
    border-radius: 5px;
    margin: 12px 0;
}

.img_input_txt {
    display: flex;
    margin: 0 auto;
    justify-content: center;
}
.showtime_inp {
    display: flex;
}
.pauseFlex {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}