.cloneVideos {
    display: inline-block;
    /* border: 1px solid #000; */
    padding: 2px 10px;
    border-radius: 3px;
    margin-right: 5px;
}
.cloneVideos select {
    width: 200px;
    height: 30px;
    padding: 3px;
}