.new_video {
    display: flex;
    justify-content: center;
    align-items: center;
}
.video_grid img {
    width: 100%;
    border-radius: 8px 8px 0 0;
    height: 230px;
}
.flex_img h2 {
    font-size: 16px;
    line-height: 28px;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* width: 120px; */
}
.dash_btns_add {
    display: flex;
}
.dash_btns_add button {
    margin: 0 5px 0 0;
}
.flex_img {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 10px 8px;
    position: relative;
    justify-content: space-between;
}
.vertical_dotimg_1{
 display: none;
}
.vertical_dotimg_1 img{
    width: 5px !important;
    height: auto;
    padding-top: 10px;
}
.video_grid {
    border: 1px solid #00000024;
    margin-bottom: 56px;
    box-shadow: 0px 4px 32px 0px #0000001a;
    border-radius: 8px;
    height: auto;
    transition: 0.5s;
    cursor: pointer;
}
.video_grid:hover .vertical_dotimg_1{
display: block;
}
.video_grid:hover {
    transform: translateY(-10px);
}
.vertical_dots {
    display: none;
    position: absolute;
    background: #fff;
    right: 0;
    bottom: -46px;
    border-radius: 8px;
    box-shadow: 0px 4px 32px 0px #0000001a;
}
.vertical_dotimg_1:hover .vertical_dots{
    display: block;
}
.vertical_dots ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.vertical_dots ul li {
    font-size: 14px;
    border-bottom: 1px solid #00000021;
    padding: 8px 22px 8px 22px;
}
.modalUpload {
    top: 40px !important;
}